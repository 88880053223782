<template>
  <div>
    <!-- <div class="image-container" v-if="file.mime_type === 'image/jpeg' && file.element_texts[0].text === 'audio-beeld'"> -->
    <div v-if="lang !== 'gt'">
      <div class="image-container" v-if="file.mime_type === 'image/jpeg' || file.mime_type === 'image/png'">
        <img class="audio-image" v-bind:src="file.file_urls.original" width="100%" height="auto" alt="object image">
      </div>
    </div>
    <div class="audio-container" v-if="file.mime_type === 'audio/mpeg' && mediaLabel === ('audio-' + lang)">
      <audio v-if="browserName !== 'ios' && browserOs !== 'iOS'" v-bind:autoplay="isAutoPlay"
             @timeupdate="updateProgress" @ended="audioEnded" @playing="audioPlaying">
        <source v-bind:src="file.file_urls.original" type="audio/mpeg">
      </audio>
      <audio v-else @timeupdate="updateProgress" @ended="audioEnded">
        <source v-bind:src="file.file_urls.original" type="audio/mpeg">
      </audio>
      <div class="control-container">
        <div v-if="bkwSkipable === true" class="button-skipbackward" @click="skipAudioBackward">
          <img src="/assets/buttons/skip-bkw.png" alt="skip backwards">
          <div v-if="mediaButtonLabels" class="player-skip-label">{{ mediaButtonLabelBackward }}</div>
        </div>
        <div v-if="isPlaying === false" class="button-play-pause" @click="playAudio">
          <img src="/assets/buttons/play.png" alt="play">
          <div v-if="mediaButtonLabels" class="player-label">{{ mediaButtonLabelPlay }}</div>
        </div>
        <div v-if="isPlaying === true" class="button-play-pause" @click="pauseAudio">
          <img src="/assets/buttons/pause.png" alt="pause">
          <div v-if="mediaButtonLabels" class="player-label">{{ mediaButtonLabelPause }}</div>
        </div>
        <div v-if="fwdSkipable === true" class="button-skipforward" @click="skipAudioForward">
          <img src="/assets/buttons/skip-fwd.png" alt="skip forwards">
          <div v-if="mediaButtonLabels" class="player-skip-label">{{ mediaButtonLabelForward }}</div>
        </div>
        <div class="progress-container">
          <div class="progress-indicator"
               v-bind:style="{width: parseInt((audioProgress / audioDuration) * 100) + '%'}"></div>
        </div>
      </div>
    </div>
    <div class="video-container" v-if="file.mime_type.substring(0,6) === 'video/' && mediaLabel === ('video-' + lang)">
      <video v-if="browserName !== 'ios' && browserOs !== 'iOS'" v-bind:autoplay="isAutoPlay"
             @timeupdate="updateProgressVideo" @ended="videoEnded" @playing="videoPlaying">
        <source v-bind:src="file.file_urls.original" :type="file.mime_type">
      </video>
      <video v-else @timeupdate="updateProgressVideo" @ended="videoEnded" playsinline>
        <source v-bind:src="file.file_urls.original" :type="file.mime_type">
      </video>
      <div class="control-container">
        <div v-if="bkwSkipable === true" class="button-skipbackward" @click="skipVideoBackward">
          <img src="/assets/buttons/skip-bkw.png" alt="skip backward">
          <div v-if="mediaButtonLabels" class="player-skip-label">{{ mediaButtonLabelBackward }}</div>
        </div>
        <div v-if="isPlaying === false" class="button-play-pause" @click="playVideo">
          <img src="/assets/buttons/play.png" alt="play">
          <div v-if="mediaButtonLabels" class="player-label">{{ mediaButtonLabelPlay }}</div>
        </div>
        <div v-if="isPlaying === true" class="button-play-pause" @click="pauseVideo">
          <img src="/assets/buttons/pause.png" alt="pause">
          <div v-if="mediaButtonLabels" class="player-label">{{ mediaButtonLabelPause }}</div>
        </div>
        <div v-if="fwdSkipable === true" class="button-skipforward" @click="skipVideoForward">
          <img src="/assets/buttons/skip-fwd.png" alt="skip forward">
          <div v-if="mediaButtonLabels" class="player-skip-label">{{ mediaButtonLabelForward }}</div>
        </div>
        <div class="progress-container">
          <div class="progress-indicator"
               v-bind:style="{width: parseInt((videoProgress / videoDuration) * 100) + '%'}"></div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios';

export default {
  name: "ObjectFileItem",
  props: ["file", "lang"],
  data() {
    return {
      browserOs: null,
      browserName: null,
      audioProgress: 0,
      audioDuration: 1,
      videoProgress: 0,
      videoDuration: 1,
      videoMime: '',
      audio: null,
      video: null,
      isVideo: false,
      mediaFile: '',
      mediaLabel: '',
      mediaButtonLabels: false,
      mediaButtonLabelPlay: '',
      mediaButtonLabelPause: '',
      mediaButtonLabelForward: '',
      mediaButtonLabelBackward: '',
      isPlaying: false,
      fwdSkipable: false,
      bkwSkipable: false,
      isAutoPlay: false
    }
  },
  methods: {
    // video
    playVideo: function () {
      let player = document.querySelector('video');
      if (player !== null) {
        this.isPlaying = true;
        player.play();
      } else {
        console.log('video element is null!');
      }
    },
    pauseVideo() {
      let player = document.querySelector('video');
      if (player !== null) {
        this.isPlaying = false;
        player.pause();
      }
    },
    skipVideoForward() {
      let player = document.querySelector('video');
      if (player !== null) {
        player.currentTime = player.currentTime + 5;
      }
    },
    skipVideoBackward() {
      let player = document.querySelector('video');
      if (player !== null) {
        if (player.currentTime > 5) {
          player.currentTime = player.currentTime - 5;
        } else {
          player.currentTime = 0;
        }
      }
    },
    updateProgressVideo() {
      let player = document.querySelector('video');
      this.videoProgress = player.currentTime;
      this.videoDuration = player.duration;
      this.bkwSkipable = true;
      if (player.duration - player.currentTime > 5) {
        this.fwdSkipable = true;
      } else {
        this.fwdSkipable = false;
      }
    },
    videoEnded() {
      this.isPlaying = false;
      this.bkwSkipable = false;
    },
    videoPlaying() {
      this.isPlaying = true;
    },
    // audio
    playAudio: function () {
      // console.log('playAudio');
      let player = document.querySelector('audio');
      if (player !== null) {
        this.isPlaying = true;
        player.play();
      } else {
        console.log('audio element is null!');
      }
    },
    pauseAudio() {
      let player = document.querySelector('audio');
      if (player !== null) {
        this.isPlaying = false;
        player.pause();
      }
    },
    skipAudioForward() {
      let player = document.querySelector('audio');
      if (player !== null) {
        player.currentTime = player.currentTime + 5;
      }
    },
    skipAudioBackward() {
      let player = document.querySelector('audio');
      if (player !== null) {
        if (player.currentTime > 5) {
          player.currentTime = player.currentTime - 5;
        } else {
          player.currentTime = 0;
        }
      }
    },
    updateProgress() {
      let player = document.querySelector('audio');
      this.audioProgress = player.currentTime;
      this.audioDuration = player.duration;
      this.bkwSkipable = true;
      if (player.duration - player.currentTime > 5) {
        this.fwdSkipable = true;
      } else {
        this.fwdSkipable = false;
      }
    },
    audioEnded() {
      this.isPlaying = false;
      this.bkwSkipable = false;
    },
    audioPlaying() {
      this.isPlaying = true;
    }
  },
  created() {
    axios.get('/config/app.json')
        .then((res) => {
          let appConfig = res.data;
          const {detect} = require('detect-browser');
          const browser = detect();
          this.browserOs = browser.os;
          this.browserName = browser.name;
          this.isAutoPlay = appConfig.autoplay;
          this.mediaButtonLabels = appConfig.label_mediabuttons;
          this.mediaButtonLabelPlay = appConfig.label_button_media_play[`${this.lang}`];
          this.mediaButtonLabelPause = appConfig.label_button_media_pause[`${this.lang}`];
          this.mediaButtonLabelForward = appConfig.label_button_media_forward[`${this.lang}`];
          this.mediaButtonLabelBackward = appConfig.label_button_media_backward[`${this.lang}`];
          let mediaPathSplit = this.file.original_filename.split('/');
          this.mediaFile = mediaPathSplit[mediaPathSplit.length - 1];
          if (this.file.element_texts[0]) {
            this.mediaLabel = this.file.element_texts[0].text;
          }
          if (this.file.mime_type.substring(0, 6) === 'video/' && this.mediaLabel === ('video-' + this.lang)) {
            this.isVideo = true;
            this.videoMime = this.file.mime_type;
          }
          console.log('media file:' + this.mediaFile);
          // testje
          // this.audio = document.querySelector('audio');
          // if(this.audio !== null) {
          //     console.log('audio element gevonden:'+this.audio.toString());
          //     console.log('browser: ' + this.browserName + ' - ' + this.browserOs);
          // } else {
          //     console.log('geen audio elementen gevonden')
          // }

        })
        .catch((err) => {
          console.log('Object fileItem config error: ' + err)
        });
  },
  beforeDestroy() {
    if (this.audio !== null) {
      this.audio.pause();
      console.log('audio geforceerd gestopt!');
    }
  },
  mounted() {
    // this.audio = document.querySelector('audio');
    // if(this.audio !== null) {
    //     console.log('audio element gevonden:'+this.audio);
    //     console.log('browser: ' + this.browserName + ' - ' + this.browserOs);
    // } else {
    //     console.log('geen audio elementen gevonden')
    // }
  }
}
</script>

<style scoped>
.file-item {
  background: #f4f4f4;
  /* padding: 10px; */
  border-bottom: 1px #ccc dotted;
}

audio {
  width: 100%;
}

.audio-container {
  position: fixed;
  bottom: 40px;
  width: 100%;
}

.audio-image {
  width: 100%;
  height: auto;
}

.video-container {
  position: fixed;
  top: 140px;
  bottom: 50px;
  width: 100%;
}

.video-container video {
  position: relative;
  width: 90%;
  height: 60%;
  margin-left: 5%;
  margin-right: 5%;
}

.image-container {
  width: 90%;
  margin-left: auto;
  margin-right: auto;
  /* margin-top: 80px; */
  /* height: 60%; */
  overflow: hidden;
}

.control-container {
  /*position: relative;*/
  position: fixed;
  bottom: 40px;
  left: 0;
  width: 90%;
  height: 85px;
  margin-left: 5%;
  margin-right: 5%;
  /* background-color: #ffffff55; */
  background-color: #666666;
  border: 1px solid #ffffff22;
  /* border-radius: 2%; */
  padding: 5px;
}

.player-label {
  width: 100%;
  text-align: center;
  margin-top: -7px;
}
.player-skip-label {
  width: 100%;
  text-align: center;
  margin-top: -17px;
}
.button-skipforward {
  position: absolute;
  display: block;
  top: 0;
  right: 5%;
  width: 60px;
  height: 50px;
}

.button-skipforward img {
  object-fit: contain;
  width: 100%;
}
.button-skipbackward {
  position: absolute;
  display: block;
  top: 0;
  left: 5%;
  width: 60px;
  height: 50px;
}

.button-skipbackward img {
  object-fit: contain;
  max-width: 100%;
}

.button-play-pause {
  /* position: absolute; */
  display: block;
  text-align: center;
  top: 0;
  width: 70px;
  height: 50px;
  margin-left: auto;
  margin-right: auto;
}

.button-play-pause img {
  /*display: inline-flex;*/
  object-fit: contain;
  max-width: 45px;
  margin-left: auto;
  margin-right: auto;
}

.progress-container {
  background-color: gray;
  width: 96%;
  height: 5px;
  margin-left: 2%;
  margin-right: 2%;
  margin-top: 15px;
}

.progress-indicator {
  background-color: white;
  height: 100%;
}
</style>
