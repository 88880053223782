<template>
  <div id="app">
    <div class="header">
<!--      <div class="sensors">-->
<!--        <center>-->
<!--          &lt;!&ndash;          <img v-if="browseActive && backToStartActive" src="/assets/buttons/start-button-diap.png" alt="Start browse" @click="showStart">&ndash;&gt;-->
<!--          <img v-if="keyActive" src="/assets/buttons/key-button-diap.png" alt="Keypad" @click="showKey">-->
<!--          <img v-if="qrActive" src="/assets/buttons/qr-button-diap.png" alt="QR Scanner" @click="showQR">-->
<!--        </center>-->
<!--      </div>-->
      <div class="sensors">
        <center>
          <div class="sensor-container" v-if="keyActive">
            <img src="/assets/buttons/key-button-diap.png" alt="Keypad" @click="showKey">
            <div v-if="headerLabels" class="sensor-label">{{ headerLabelKeypad }}</div>
          </div>
          <div v-if="qrActive" class="sensor-container">
            <img src="/assets/buttons/qr-button-diap.png" alt="QR Scanner" @click="showQR">
            <div v-if="headerLabels" class="sensor-label">{{ headerLabelQR }}</div>
          </div>
          <div v-if="bbActive" class="sensor-container">
            <img src="/assets/buttons/list-button-diap.png" alt="Subject Buttons" @click="showBB">
            <div v-if="headerLabels" class="sensor-label">{{ headerLabelList }}</div>
          </div>
          <div v-if="groupsActive" class="sensor-container">
            <img src="/assets/buttons/list-button-diap.png" alt="Group Buttons" @click="showGroups">
            <div v-if="headerLabels" class="sensor-label">{{ headerLabelGroups }}</div>
          </div>
        </center>
      </div>
      <div class="home">
        <img src="/assets/buttons/home-wit-schaduw.png" alt="home" @click="showWelcome">
        <div v-if="headerLabels" class="home-label">Home</div>
      </div>
      <div class="flag">
        <img v-if="langId !== 'x'" :src="'/assets/buttons/flags/'+langId+'_small.jpg'" @click="showHome">
        <img v-if="langId === 'x'" :src="'/assets/buttons/flags/'+languageX+'_small.jpg'" @click="showHome">
        <div v-if="headerLabels" class="flag-label">{{ headerLabelFlag }}</div>
      </div>
      <div class="divider">
        <center>
          <hr>
        </center>
      </div>
    </div>
    <div v-if="bbWide && bbNr && !buttonImage" class="buttons-container">
      <div class="key-wide-left" v-bind:key="item.id" v-for="item in itemList" @click="getGroupObjects(item.groupTag)">
        <div>{{ item.title }}</div>

      </div>
    </div>
    <div v-if="bbWide && !bbNr && !buttonImage" class="buttons-container">
      <div class="key-wide" v-bind:key="item.id" v-for="item in itemList" @click="getGroupObjects(item.groupTag)">
        <div>{{ item.id }}: {{ item.title }}</div>
      </div>
    </div>
    <div v-if="bbWide && !bbNr && buttonImage" class="buttons-container">
      <div class="key-wide-image" v-bind:key="item.id" v-for="item in itemList" @click="getGroupObjects(item.groupTag)">
        <div><span class="btn-image" v-if="item.thumb"><img :src="item.thumb"></span><span
            class="label-image">{{ item.title }}</span></div>
      </div>
    </div>
    <div v-if="!bbWide" class="buttons-container">
      <div class="key" v-bind:key="item.id" v-for="item in itemList" @click="getGroupObjects(item.groupTag)">
        <span>{{ item.label }}</span>
      </div>
    </div>
    <div class="footer">
      <hr>
      <div class="copy-right">&copy; Easycast Audio Tour</div>
    </div>
  </div>
</template>

<script>
import axios from 'axios';
import router from '../router';

export default {
  name: "ButtonBoard",
  props: ['langId'],
  data() {
    return {
      apiUrl: null,
      apiKey: null,
      apiCollection: null,
      qrActive: false,
      keyActive: false,
      groupsActive: false,
      bbActive: false,
      browseStart: false,
      browseActive: false,
      backToStartActive: false,
      bbWide: false,
      bbNr: false,
      buttonImage: false,
      headerLabels: false,
      headerLabelHome: '',
      headerLabelList: '',
      headerLabelGroups: '',
      headerLabelKeypad: '',
      headerLabelQR: '',
      headerLabelFlag: '',
      languageX: '',
      itemList: []
    }
  },
  methods: {
    showHome: function () {
      router.push('/');
    },
    showWelcome: function () {
      router.push('/welcome/' + this.langId);
    },
    showIntro: function () {
      router.push('/intro/' + this.langId);
    },
    getGroupObjects: function (tagIn) {
      router.push('/group/' + tagIn + '/' + this.langId);
    },
    showQR: function () {
      router.push('/qr/' + this.langId);
    },
    showKey: function () {
      router.push('/key/' + this.langId);
    },
    getObject: function (idIn) {
      router.push('/object/' + this.langId + '/' + idIn);
    }
  },
  created() {
    axios.get('/config/app.json')
        .then((res) => {
          let configData = res.data;
          this.qrActive = configData.qrscanner;
          this.keyActive = configData.keypad;
          this.browseActive = configData.browse;
          this.backToStartActive = configData.startbutton;
          this.browseStart = configData.browse_start;
          this.bbWide = configData.bbwide;
          this.bbNr = configData.bbnr;
          this.buttonImage = configData.buttonimage;
          // labels for buttons header
          this.headerLabels = configData.label_headerbuttons;
          this.headerLabelHome = configData.label_button_header_home[`${this.langId}`];
          this.headerLabelList = configData.label_button_header_list[`${this.langId}`];
          this.headerLabelGroups = configData.label_button_header_groups[`${this.langId}`];
          this.headerLabelKeypad = configData.label_button_header_keypad[`${this.langId}`];
          this.headerLabelQR = configData.label_button_header_qr[`${this.langId}`];
          this.headerLabelFlag = configData.label_button_header_flag[`${this.langId}`];

          this.apiUrl = configData.api;
          this.apiKey = configData.api_key;
          this.apiCollection = configData.api_collection;
          this.languageX = configData.language_x;
          //210324 - because of introduction new object-type: audio-group query has to include item_type:18 audiotour-item
          axios.get(this.apiUrl + 'items?collection=' + this.apiCollection + '&item_type=19&key=' + this.apiKey)
              .then((itemsResult) => {
                if (itemsResult) {
                  itemsResult.data.forEach((item) => {
                    // actief
                    item.element_texts.forEach((maintext_element) => {
                      if (maintext_element.element.name === 'Actief') {
                        if (maintext_element.text === '1') {
                          // titel
                          item.element_texts.forEach((text_element) => {
                            if (text_element.element.name === 'Titel_' + this.langId) {
                              if (text_element.text !== '') {
                                let itemToPush = {};
                                //tag naam
                                item.element_texts.forEach((elementFound) => {
                                  if (elementFound.element.name === 'Tag naam') {
                                    itemToPush.id = item.id;
                                    if (elementFound.text !== '') {
                                      itemToPush.groupTag = elementFound.text;
                                      itemToPush.title = text_element.text;
                                      // console.log('tag for selection: ' + itemToPush.groupTag);
                                      // files
                                      if (item.files.count > 0) {
                                        // get image
                                        axios.get(this.apiUrl + 'files?item=' + item.id + '&key=' + this.apiKey)
                                            .then((filesResult) => {
                                              itemToPush.thumb = filesResult.data[0].file_urls.thumbnail;
                                              // console.log('group image found: ' + itemToPush.thumb);
                                              // console.log(JSON.stringify(itemToPush));
                                              this.itemList.push(itemToPush);
                                              this.itemList.sort((a, b) => {
                                                return a.id - b.id;
                                              });
                                            })
                                            .catch((err) => {
                                              this.itemList.push(itemToPush);
                                              console.log('file request error: ' + err);
                                            });
                                      } else {
                                        this.itemList.push(itemToPush);
                                      }
                                    }
                                  }
                                });
                                //    einde tag naam
                              }
                            }
                          });
                          //  einde titel

                        }
                      }
                    });
                    // einde actief
                  });
                  console.log('itemList: ' + JSON.stringify(this.itemList));
                } else {
                  console.log('nothing found!');
                }
              })
              .catch((err) => {
                console.log('created(): error getting itemlist: ' + err)
              });
        })
        .catch((err) => {
          console.log('created(): error config: ' + err)
        });
  }
}
</script>

<style scoped>
.buttons-container {
  margin-top: 100px;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 80px;
  position: relative;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  text-align: center;
  width: 90%;
  max-width: 600px;
}

.buttons-container .key {
  display: inline-flex;
  flex: 0 0 30%;
  /* padding: 10px; */
  font-family: 'robotobold', 'Courier New', Courier, monospace;
  font-size: 25px;
  font-weight: bold;
  /* width: 33%; */
  height: 60px;
  background-color: white;
  color: black;
  text-align: center;
  vertical-align: middle;
  margin: 5px;
  border-radius: 5px;
}

.buttons-container .key-wide {
  display: inline-flex;
  flex: 0 0 100%;
  /* padding: 10px; */
  font-family: 'robotobold', 'Courier New', Courier, monospace;
  font-size: 25px;
  font-weight: bold;
  /* width: 33%; */
  height: 60px;
  background-color: white;
  color: black;
  text-align: center;
  vertical-align: middle;
  margin: 5px;
  border-radius: 5px;
  padding-left: 5px;
  padding-right: 5px;
}

.buttons-container .key-wide-image {
  display: inline-flex;
  position: relative;
  flex: 0 0 100%;
  /* padding: 10px; */
  font-family: 'robotobold', 'Courier New', Courier, monospace;
  font-size: 25px;
  font-weight: bold;
  /* width: 33%; */
  height: 60px;
  background-color: white;
  color: black;
  /*text-align: center;*/
  vertical-align: middle;
  margin: 5px;
  border-radius: 5px;
  padding-left: 5px;
  padding-right: 5px;
}

.buttons-container .key-wide-left {
  display: inline-flex;
  flex: 0 0 100%;
  /* padding: 10px; */
  font-family: 'robotobold', 'Courier New', Courier, monospace;
  font-size: 25px;
  font-weight: bold;
  /* width: 33%; */
  height: 60px;
  background-color: white;
  color: black;
  text-align: left;
  vertical-align: middle;
  margin: 5px;
  border-radius: 5px;
  padding-left: 5px;
  padding-right: 5px;
}

.buttons-container .key span {
  width: 100%;
  height: 100%;
  text-align: center;
  padding-top: 15px;
}

.buttons-container .key-wide div {
  width: 100%;
  height: 100%;
  text-align: center;
  padding-top: 15px;
}

.buttons-container .key-wide div span.nr {
  width: 30%;
  height: 100%;
  text-align: center;
  padding-top: 15px;
}

.buttons-container .key-wide-image div span.label-image {
  position: absolute;
  width: auto;
  left: 90px;
  top: 12px;
}

.buttons-container .key-wide-image div span.btn-image {
  position: absolute;
  width: 80px;
  height: 100%;
  left: 0;
}

.buttons-container .key-wide-image div span.btn-image img {
  width: 100%;
  height: 60px;
  /*margin-top: -10px;*/
  object-fit: cover;
}


.buttons-container .key-wide-left div {
  width: 100%;
  height: 100%;
  text-align: left;
  padding-top: 15px;
}

.buttons-container .key-wide-left div span.nr {
  width: 30%;
  height: 100%;
  text-align: left;
  padding-top: 15px;
}

</style>
