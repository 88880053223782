<template>
  <div id="app">
    <!-- <Header /> -->
    <router-view :key="$route.fullPath"/>
  </div>
</template>
<script>
// import Header from './components/layouts/Header'
export default {
  name: "app",
  components: {
    // Header
  }
}
</script>
<style>
@font-face {
  font-family: 'robotoregular';
  src: url('/assets/fonts/roboto/Roboto-Regular-webfont.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'robotobold';
  src: url('/assets/fonts/roboto/Roboto-Bold-webfont.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'robotomedium';
  src: url('/assets/fonts/roboto/Roboto-Medium-webfont.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

body {
  background-color: black;
}

#app {
  font-family: 'robotoregular', Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: white;
  background-color: black;
}

.header {
  position: fixed;
  top: 0;
  width: 100%;
  height: 75px;
  z-index: 999;
  background-color: black;
}

.header .home {
  display: inline-flex;
  position: absolute;
  top: 5px;
  left: 5%;
  height: 70px;
  width: 60px;
  margin-left: 0px;
  margin-right: 2px;
}

.header .home img {
  width: 100%;
  height: auto;
  max-height: 50px;
  object-fit: contain;
}

.header .home .home-label {
  text-align: center;
  position: absolute;
  bottom: 0;
  width: 100%;
  font-size: 12px;
  font-weight: bold;
  color: white;
}

.header .flag {
  position: absolute;
  right: 5%;
  top: 5px;
  display: inline-flex;
  height: 70px;
  width: 80px;
  margin-left: 0px;
  margin-right: 2px;
}

.header .flag img {
  width: 100%;
  height: auto;
  max-height: 50px;
  object-fit: contain;
}

.header .flag .flag-label {
  text-align: center;
  position: absolute;
  bottom: 0;
  width: 100%;
  font-size: 12px;
  font-weight: bold;
  color: white;
}

.header .sensors {
  position: absolute;
  padding: 5px;
  height: 60px;
  width: 100%;
  top: 0px;
  /* align-items: center; */
}

.sensor-container {
  display: inline-flex;
  position: relative;
  height: 70px;
  width: 60px;
  margin-left: 2px;
  margin-right: 2px;
}

.sensor-container img {
  width: 100%;
  height: auto;
  max-height: 50px;
  object-fit: contain;
}

.sensor-container .sensor-label {
  text-align: center;
  position: absolute;
  bottom: 0;
  width: 100%;
  font-size: 12px;
  font-weight: bold;
  color: white;
}

.header .divider {
  width: 100%;
  position: absolute;
  top: 75px;
}

.header .divider hr {
  /* margin-top: 5px; */
  width: 90%;
}
</style>
