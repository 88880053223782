<template>
  <div id="app">
    <div class="header">
<!--      <div class="sensors">-->
<!--        <center>-->
<!--          <img v-if="keyActive" src="/assets/buttons/key-button-diap.png" alt="Keypad" @click="showKey">-->
<!--          <img v-if="bbActive" src="/assets/buttons/list-button-diap.png" alt="Subject Buttons" @click="showBB">-->
<!--          <img v-if="groupsActive" src="/assets/buttons/list-button-diap.png" alt="Group Buttons" @click="showGroups">-->
<!--        </center>-->
<!--      </div>-->
      <div class="sensors">
        <center>
          <div class="sensor-container" v-if="keyActive">
            <img src="/assets/buttons/key-button-diap.png" alt="Keypad" @click="showKey">
            <div v-if="headerLabels" class="sensor-label">{{ headerLabelKeypad }}</div>
          </div>
          <div v-if="qrActive" class="sensor-container">
            <img src="/assets/buttons/qr-button-diap.png" alt="QR Scanner" @click="showQR">
            <div v-if="headerLabels" class="sensor-label">{{ headerLabelQR }}</div>
          </div>
          <div v-if="bbActive" class="sensor-container">
            <img src="/assets/buttons/list-button-diap.png" alt="Subject Buttons" @click="showBB">
            <div v-if="headerLabels" class="sensor-label">{{ headerLabelList }}</div>
          </div>
          <div v-if="groupsActive" class="sensor-container">
            <img src="/assets/buttons/list-button-diap.png" alt="Group Buttons" @click="showGroups">
            <div v-if="headerLabels" class="sensor-label">{{ headerLabelGroups }}</div>
          </div>
        </center>
      </div>
      <div class="home">
        <img src="/assets/buttons/home-wit-schaduw.png" alt="home" @click="showWelcome">
        <div v-if="headerLabels" class="home-label">Home</div>
      </div>
      <div class="flag">
        <img v-if="langId !== 'x'" :src="'/assets/buttons/flags/'+langId+'_small.jpg'" @click="showHome">
        <img v-if="langId === 'x'" :src="'/assets/buttons/flags/'+languageX+'_small.jpg'" @click="showHome">
        <div v-if="headerLabels" class="flag-label">{{ headerLabelFlag }}</div>
      </div>
      <div class="divider">
        <center>
          <hr>
        </center>
      </div>
    </div>
    <div class="qr-container">
      <qrcode-stream @decode="handleQR"></qrcode-stream>
    </div>
    <div class="footer">
      <hr>
      <div class="copy-right">&copy; Easycast Audio Tour</div>
    </div>
  </div>
</template>

<script>
import axios from 'axios';
import {QrcodeStream} from 'vue-qrcode-reader';
import router from '../router';

export default {
  name: 'QRScanner',
  components: {
    // Objects,
    QrcodeStream
  },
  props: ['langId'],
  data() {
    return {
      objects: [],
      apiUrl: null,
      apiKey: null,
      apiCollection: null,
      keyActive: false,
      bbActive: false,
      groupsActive: false,
      qrActive: false,
      headerLabels: false,
      headerLabelHome: '',
      headerLabelList: '',
      headerLabelGroups: '',
      headerLabelKeypad: '',
      headerLabelQR: '',
      headerLabelFlag: '',
      languageX: ''
    }
  },
  methods: {
    showHome: function () {
      router.push('/');
    },
    showWelcome: function () {
      router.push('/welcome/' + this.langId);
    },
    showIntro: function () {
      router.push('/intro/' + this.langId);
    },
    showKey: function () {
      router.push('/key/' + this.langId);
    },
    showBB: function () {
      router.push('/buttons/' + this.langId);
    },
    showGroups: function () {
      router.push('/groups/' + this.langId);
    },
    handleQR(qrFound) {
      if (qrFound.substr(0, 6) === 'audio-') {
        axios.get(this.apiUrl + 'items?tags=' + qrFound + '&collection=' + this.apiCollection + '&key=' + this.apiKey)
            .then((res) => {
                  var objectId = res.data[0].id;
                  router.push('/object/' + this.langId + '/' + objectId);
                }
            )
            .catch(err => console.log(err));
      }
    }
  },
  created() {
    axios.get('/config/app.json')
        .then((res) => {
          let configData = res.data;
          this.apiUrl = configData.api;
          this.apiKey = configData.api_key;
          this.apiCollection = configData.api_collection;
          this.keyActive = configData.keypad;
          this.bbActive = configData.buttonboard;
          this.groupsActive = configData.groups;
          // labels for buttons header
          this.headerLabels = configData.label_headerbuttons;
          this.headerLabelHome = configData.label_button_header_home[`${this.langId}`];
          this.headerLabelList = configData.label_button_header_list[`${this.langId}`];
          this.headerLabelGroups = configData.label_button_header_groups[`${this.langId}`];
          this.headerLabelKeypad = configData.label_button_header_keypad[`${this.langId}`];
          this.headerLabelQR = configData.label_button_header_qr[`${this.langId}`];
          this.headerLabelFlag = configData.label_button_header_flag[`${this.langId}`];
          this.languageX = configData.language_x;
        })
        .catch((err) => {
          console.log('error config: ' + err)
        })
  }
}
</script>

<style scoped>
* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

body {
  font-family: Arial, Helvetica, sans-serif;
  line-height: 1.4;
}

.qrcode-stream__camera,
.qrcode-stream__pause-frame {
  position: fixed;
  right: 0;
  bottom: 0;
  min-width: 100%;
  min-height: 100%;
  width: auto;
  height: auto;
  background-size: cover;
  max-width: inherit !important;
  max-height: inherit !important;
}

.qrcode-stream__inner-wrapper {
  position: inherit !important;
  max-width: inherit !important;
  max-height: inherit !important;
  z-index: inherit !important;
}

.btn {
  display: inline-block;
  border: none;
  background: #555;
  color: #fff;
  padding: 7px 20px;
  cursor: pointer;
}

.btn:hover {
  background: #666;
}

.qr-container {
  /* position: absolute; */
  /* top: 100px; */
  color: white;
  margin-top: 80px;
  width: 90%;
  margin-left: auto;
  margin-right: auto;
}

.footer {
  position: fixed;
  bottom: 0;
  width: 100%;
  padding-left: 5%;
  padding-right: 5%;
  color: white;
  text-align: center;
  background-color: black;
}

.copy-right {
  padding-top: 6px;
  padding-bottom: 6px;
}
</style>
