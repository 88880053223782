<template>
  <div id="app">
    <center>
      <div class="container">
        <div class="flag" v-if="nlActive">
          <img src="/assets/buttons/flags/nl_large.jpg" alt="Nederlands" @click="showWelcome('nl')">
          <div class="label">Kies uw taal</div>
        </div>
        <div class="flag" v-if="gtActive">
          <img src="/assets/buttons/flags/gt_large.jpg" alt="Gebarentaal" @click="showWelcome('gt')">
          <div class="label">Kies gebarentaal</div>
        </div>
        <div class="flag" v-if="deActive">
          <img src="/assets/buttons/flags/de_large.jpg" alt="Deutsch" @click="showWelcome('de')">
          <div class="label">Bitte wählen Sie Ihre Sprache</div>
        </div>
        <div class="flag" v-if="enActive">
          <img src="/assets/buttons/flags/en_large.jpg" alt="English" @click="showWelcome('en')">
          <div class="label">Choose your Language</div>
        </div>
        <div class="flag" v-if="frActive">
          <img src="/assets/buttons/flags/fr_large.jpg" alt="Francais" @click="showWelcome('fr')">
          <div class="label">Choisissez votre langue</div>
        </div>
        <div class="flag" v-if="xActive">
          <img src="/assets/buttons/flags/frl_large.jpg" alt="Frysk" @click="showWelcome('x')">
          <div class="label">Kies jo taal</div>
        </div>
      </div>
    </center>
    <div class="footer">
      <hr>
      <div class="copy-right">&copy; Easycast Audio Tour</div>
    </div>
  </div>
</template>

<script>
// import configData from '../../public/config/app.json';
import router from '../router';
import axios from 'axios';

export default {
  name: 'Home',
  components: {},
  data() {
    return {
      nlActive: false,
      deActive: false,
      enActive: false,
      frActive: false,
      xActive: false,
      gtActive: false,
      headerLabelHome: '',
      headerLabelList: '',
      headerLabelKeypad: '',
      headerLabelQR: '',
      headerLabelFlag: '',
      languages: []
    }
  },
  methods: {
    showWelcome: function (langIn) {
      router.push('/welcome/' + langIn);
    },
    showIntro: function (langIn) {
      router.push('/intro/' + langIn);
    }
  },
  created() {
    axios.get('/config/app.json')
        .then((res) => {
          let configData = res.data;
          this.languages = configData.languages;
          this.languages.forEach((language) => {
            if (language === 'nl') {
              this.nlActive = true;
            }
            if (language === 'de') {
              this.deActive = true;
            }
            if (language === 'en') {
              this.enActive = true;
            }
            if (language === 'fr') {
              this.frActive = true;
            }
            if (language === 'gt') {
              this.gtActive = true;
            }
            if (language === 'x') {
              this.xActive = true;
            }
            if (this.languages.length === 1) {
              let languageToUse = this.languages[0];
              router.push('/welcome/' + languageToUse);
            }
          });
        })
        .catch((err) => {
          console.log('config error: ' + err)
        });
  }
}
</script>

<style>
* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

body {
  font-family: Arial, Helvetica, sans-serif;
  line-height: 1.4;
}

.container {
  margin-top: 50px;
  /* align-items: center; */
  /* display: flex; */
  /* flex-wrap: wrap; */
  max-width: 800px;
  width: 80%;
  /* margin-top: 60px; */
  max-height: 80%;
}

.container .flag {
  /* display: inline-flex; */
  /* display: -webkit-inline-flex; */
  /* padding: 10px; */
  padding-bottom: 15px;
  width: 50%;
}

.container .flag img {
  width: 80%;
  height: auto;
  object-fit: contain;
}

.container .flag .label {
  width: 100%;
  color: white;
  text-align: center;
  font-family: robotomedium, Arial, Helvetica, sans-serif;
  font-size: 1em;
}

.btn {
  display: inline-block;
  border: none;
  background: #555;
  color: #fff;
  padding: 7px 20px;
  cursor: pointer;
}

.btn:hover {
  background: #666;
}

.qrcode-stream__camera,
.qrcode-stream__pause-frame {
  position: fixed;
  right: 0;
  bottom: 0;
  min-width: 100%;
  min-height: 100%;
  width: auto;
  height: auto;
  background-size: cover;
  max-width: inherit !important;
  max-height: inherit !important;
}

.qrcode-stream__inner-wrapper {
  position: inherit !important;
  max-width: inherit !important;
  max-height: inherit !important;
  z-index: inherit !important;
}

.footer {
  position: fixed;
  bottom: 0;
  width: 100%;
  padding-left: 5%;
  padding-right: 5%;
  color: white;
  text-align: center;
  background-color: black;
}

.copy-right {
  padding-top: 6px;
  padding-bottom: 6px;
}
</style>
